import React, { useEffect, useState } from 'react';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import {
  automaticCollectionUnenrollment,
  getIntegrationStatus,
  getPublicationStatus,
} from '../../../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from '../../../../../../../../lib/designSystem/Alert';
import { Snackbar } from '@mui/material';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import NewEnrollment from './components/NewEnrollment';
import PaymentNotifications from './components/PaymentNotifications';
import { validatePermissions } from '../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../services/redux/authSlice';
import FilterButton from '../../../../../../../../lib/designSystem/FilterButton';
import PublicationForm from '../../../../../../../../pages/Debt/components/InvoicePublication/components/PublicationForm';
import moment from 'moment';
import DocumentManagements from './components/DocumentManagements';
import { getDocumentsForCollection } from '../../../../../../../../lib/api/collections';

const headers: { key: string; label: string; type: any }[] = [
  { key: 'businessName', label: 'Empresa', type: '' },
  { key: 'stakeholderName', label: 'Receptor', type: '' },
  { key: 'folio', label: 'Folio', type: '' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'paymentDate', label: 'Vencimiento', type: 'date' },
  { key: 'invoiceType', label: 'Tipo', type: 'invoice-type' },
  {
    key: 'lastCollectionManagementState',
    label: 'Estado gestión',
    type: '',
  },
  {
    key: 'autoCollectionStatus',
    label: 'Auto',
    type: 'auto-collection-status',
  },
  {
    key: 'amountRemaining',
    label: 'Pendiente',
    type: 'invoice-remaining-amount',
  },
];

const transformDteType = (document: string) => {
  switch (document) {
    case 'electronic-invoice':
      return '33';
    case 'electronic-invoice-exempt':
      return '34';
    default:
      return '';
  }
};

const transformDocType = (document: string) => {
  switch (document) {
    case 'electronic-invoice':
      return 'Factura';
    default:
      return '';
  }
};

/* This set the id for the Cobranza Online integration */
const COBRANZA_ONLINE_ID = 1;

const CollectionDocuments: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [reload, setReload] = useState(0);
  const [customAlert, setCustomAlert] = useState({
    open: false,
    severity: 'error',
    message: '',
  });
  const [openModalAutoCollection, setOpenModalAutoCollection] = useState({
    open: false,
    id: 0,
    stakeholderIdentifier: '',
  });
  const [openPaymentNotifications, setOpenPaymentNotifications] = useState<{
    invoiceId: any;
    open: boolean;
  }>({
    invoiceId: 0,
    open: false,
  });
  const [documentToPublish, setDocumentToPublish] = useState<any>();
  const [integrationStatus, setIntegrationStatus] = useState(false);

  const filters = [
    {
      key: 'stakeholderName',
      label: 'Receptor',
      options: [...new Set(originalData.map((x: any) => x.stakeholderName))],
      value: '',
    },
    {
      key: 'lastCollectionManagementState',
      label: 'Estado última gestión',
      options: [
        ...new Set(
          originalData.map((x: any) => x.lastCollectionManagementState),
        ),
      ],
      value: '',
    },
    {
      key: 'issuedDate',
      label: 'Fecha de emisión inicio',
      value: null,
      type: 'startDate',
    },
    {
      key: 'issuedDate',
      label: 'Fecha de emisión fin',
      value: null,
      type: 'endDate',
    },
  ];

  const handleManagement = async (id: string) => {
    setSelectedDocumentId(id);
    setOpen(true);
  };

  const canShowOpenDetails = (id: number) => {
    const request = originalData.find((x: any) => x.id === id);
    if (!request) return alert('Ha ocurrido un error');

    return true;
  };

  const canShowAutomaticEnroll = (id: number) => {
    if (!validatePermissions(user, 'collections_create')) {
      return false;
    }
    const element = originalData.find((x: any) => x.id === id);
    if (!element) return alert('Ha ocurrido un error');
    if (
      element.collectionManagements.filter(
        (x: any) => x.type === 'auto-enrollment',
      ).length <=
        element.collectionManagements.filter(
          (x: any) => x.type === 'auto-unenrollment',
        ).length &&
      !element.paid
    )
      return true;

    if (
      element.collectionManagements.filter(
        (x: any) => x.type === 'auto-enrollment',
      ).length === 0
    )
      return true;

    return false;
  };

  const canShowAutomaticUnEnroll = (id: number) => {
    if (!validatePermissions(user, 'collections_create')) {
      return false;
    }
    const element = originalData.find((x: any) => x.id === id);
    if (!element) return alert('Ha ocurrido un error');
    if (
      element.collectionManagements.filter(
        (x: any) => x.type === 'auto-unenrollment',
      ).length <
        element.collectionManagements.filter(
          (x: any) => x.type === 'auto-enrollment',
        ).length &&
      !element.paid
    )
      return true;
    return false;
  };

  const handleAutomaticUnEnroll = async (id: number) => {
    setLoading(true);
    try {
      await automaticCollectionUnenrollment(dispatch, {
        id: id,
        type: originalData.find((x: any) => x.id === id)?.invoiceType,
      });
      setSnackbar({ message: 'Factura desenrolada con éxito', open: true });
      setReload(reload + 1);
    } catch (error) {
      alert('Ha ocurrido un error');
      setReload(reload + 1);
    }
  };

  const handlePublish = async (id: number) => {
    const document = originalData.find((x: any) => x.id === id);
    const published = await getPublicationStatus(
      dispatch,
      document.folio,
      document.companyIdentifier,
      document.stakeholderIdentifier,
    );
    if (published.published) {
      setCustomAlert({
        open: true,
        severity: 'error',
        message: 'Este documento ya está publicado en Cobranza Online',
      });
      return;
    }
    if (!document) return alert('Ha ocurrido un error');
    console.log(document);
    const contact = document.contacts.find(
      (contact: any) => contact.invoicing === true,
    );
    setDocumentToPublish({
      stakeholderIdentifier: document.stakeholderIdentifier,
      businessIdentifier: document.companyIdentifier,
      debtorLegalName: document.stakeholderName,
      folio: document.folio,
      amount: document.amount,
      issueDate: moment(document.issuedDate),
      dueDate: moment(document.paymentDate),
      docType: transformDocType(document.documentType),
      invoiceCode: transformDteType(document.documentType),
      contactName: contact?.name.split(' ').slice(0, -1).join(' ') || '',
      contactEmail: contact?.email || '',
      contactPhone: contact?.phone || '',
      contactLastName: contact?.name.split(' ').slice(-1).join(' ') || '',
    });
    setOpenPublishModal(true);
  };

  const getCobranzaOnlineStatus = async () => {
    try {
      const response = await getIntegrationStatus(dispatch, COBRANZA_ONLINE_ID);
      setIntegrationStatus(response.status);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenAutoCollectionModal = (id: number) => {
    const document = originalData.find((x: any) => x.id === id);
    if (!document) return alert('Ha ocurrido un error');

    setOpenModalAutoCollection({
      open: true,
      id: id,
      stakeholderIdentifier: document.stakeholderIdentifier,
    });
  };

  const actions = [
    {
      action: handleManagement,
      actionLabel: 'Gestiones',
      canShow: canShowOpenDetails,
    },
    {
      action: handleOpenAutoCollectionModal,
      actionLabel: 'Enrolar a cobranza automática',
      canShow: canShowAutomaticEnroll,
    },
    {
      action: handleAutomaticUnEnroll,
      actionLabel: 'Desenrolar de cobranza automática',
      canShow: canShowAutomaticUnEnroll,
    },
    {
      action: (id: number) =>
        setOpenPaymentNotifications({ invoiceId: id, open: true }),
      actionLabel: 'Notificaciones de pago',
      canShow: canShowOpenDetails,
      notifications: true,
    },
    {
      action: handlePublish,
      actionLabel: 'Publicar en cobranza online',
      canShow: () => integrationStatus,
    },
  ];

  const fetchInvoices = async () => {
    setLoading(true);
    const results = await getDocumentsForCollection(dispatch);
    setDataTable(
      results.sort((a: any, b: any) => b.notifications - a.notifications) ||
        [] ||
        [],
    );
    setOriginalData(
      results.sort((a: any, b: any) => b.notifications - a.notifications) ||
        [] ||
        [],
    );
    await getCobranzaOnlineStatus();
    setLoading(false);
  };

  useEffect(() => {
    fetchInvoices();
  }, [reload]);

  return (
    <>
      {loading && <CenteredCircularProgress type="layout" />}
      {!loading && (
        <div>
          <Snackbar
            open={snackbar.open}
            onClose={() => setSnackbar({ message: '', open: false })}
            message={snackbar.message}
          />
          <PaymentNotifications
            invoiceId={openPaymentNotifications.invoiceId}
            open={openPaymentNotifications.open}
            setOpen={setOpenPaymentNotifications}
            fetchInvoices={fetchInvoices}
          />
          {openModalAutoCollection.open && (
            <NewEnrollment
              setModal={setOpenModalAutoCollection}
              id={openModalAutoCollection.id}
              stakeholderIdentifier={
                openModalAutoCollection.stakeholderIdentifier
              }
              open={openModalAutoCollection.open}
              invoiceData={originalData.find(
                (x: any) => x.id === openModalAutoCollection.id,
              )}
              setSnackbar={setSnackbar}
              reload={reload}
              setReload={setReload}
              setLoading={setLoading}
            />
          )}
          <EnhancedTable
            dataHeaders={headers}
            data={dataTable}
            showActions
            caption="Documentos pendientes de cobro"
            rowsPerPageDefault={50}
            actions={actions}
            exportToFile="xlsx"
            buttons={[
              {
                customButton: (
                  <FilterButton
                    buttonLabel="Filtrar"
                    filters={filters}
                    setData={setDataTable}
                    originalData={originalData}
                  />
                ),
              },
            ]}
          />
          {open && (
            <DocumentManagements
              documentId={selectedDocumentId}
              open={open}
              setOpen={setOpen}
              setOpenSucessAlert={setOpenAlert}
              setOpenErrorAlert={setCustomAlert}
            />
          )}
          <CustomAlert
            open={openAlert}
            title="Gestión guardada con éxito"
            onClose={() => {
              setOpenAlert(false);
            }}
            setOpen={setOpenAlert}
            text=""
            severity="success"
            size="small"
            variant="outlined"
          />
          <CustomAlert
            open={customAlert.open}
            setOpen={setCustomAlert}
            severity={customAlert.severity}
            title={customAlert.message}
          />
          <PublicationForm
            open={openPublishModal}
            setOpen={setOpenPublishModal}
            setReload={setReload}
            reload={reload}
            setCustomAlert={setCustomAlert}
            data={documentToPublish}
          />
        </div>
      )}
    </>
  );
};

export default CollectionDocuments;
