import React, { useEffect, useState } from 'react';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import { getFutureCollectionManagementsByCompanyId } from '../../../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  flattenArrayObject,
  validatePermissions,
} from '../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../services/redux/authSlice';
import CustomAlert from '../../../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import NewManagement from '../../../Home/components/Documents/components/NewManagement';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';

const adminHeaders: { key: string; label: string; type: any }[] = [
  { key: 'name', label: 'Nombre', type: '' },
  { key: 'issuedDate', label: 'Fecha emisión', type: 'date' },
  { key: 'paymentDate', label: 'Fecha pago', type: 'date' },
  { key: 'stakeholderName', label: 'Deudor', type: '' },
  { key: 'folio', label: 'Folio documento', type: '' },
  { key: 'amountRemaining', label: 'Monto adeudado', type: 'money' },
];

const regularHeaders: { key: string; label: string; type: any }[] = [
  { key: 'name', label: 'Nombre', type: '' },
  { key: 'issuedDate', label: 'Fecha vencimiento', type: 'date' },
  { key: 'paymentDate', label: 'Fecha pago', type: 'date' },
  { key: 'stakeholderName', label: 'Deudor', type: '' },
  { key: 'folio', label: 'Folio factura', type: '' },
  { key: 'amountRemaining', label: 'Monto adeudado', type: 'money' },
];

const CollectionsManagementsSuggested: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [collectionsData, setCollectionsData] = useState<any[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const PAGE_SIZE = 10;
  const handleManagement = async (id: string) => {
    const document = collectionsData.find((document) => document.id === id);
    if (document) {
      setSelectedDocument(document);
      setOpenModal(true);
    }
  };

  const actions = [
    {
      action: handleManagement,
      actionLabel: 'Completar',
    },
  ];

  const fetchCollections = async () => {
    setLoading(true);
    try {
      const results: any[] =
        flattenArrayObject(
          await getFutureCollectionManagementsByCompanyId(dispatch),
        ) || [];
      setCollectionsData(results);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title={'Sugeridas'}
        variant={'body1'}
        fontWeight="bold"
      />
      <EnhancedTable
        dataHeaders={
          validatePermissions(user, 'collection_admin')
            ? adminHeaders
            : regularHeaders
        }
        data={collectionsData}
        rowsPerPageDefault={PAGE_SIZE}
        actions={actions}
        showActions
      />
      <CustomAlert
        open={openAlert}
        title="Gestión completada"
        onClose={() => {
          setOpenAlert(false);
        }}
        setOpen={setOpenAlert}
        text=""
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={openErrorAlert}
        title="Error al completar la gestión"
        onClose={() => {
          setOpenErrorAlert(false);
        }}
        setOpen={setOpenErrorAlert}
        text=""
        severity="error"
        size="small"
        variant="outlined"
      />
      {openModal && (
        <NewManagement
          openModal={openModal}
          setOpenModal={setOpenModal}
          document={selectedDocument}
          setOpenSucessAlert={setOpenAlert}
          setOpenErrorAlert={setOpenErrorAlert}
          setUp={fetchCollections}
        />
      )}
    </div>
  );
};

export default CollectionsManagementsSuggested;
