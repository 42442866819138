import React, { useState } from 'react';
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CustomAlert from '../../../../../../../../lib/designSystem/Alert';
import { validaRut } from '../../../../../../../../lib/functions/utils';
import MenuDialog from '../../../../../../../../lib/designSystem/MenuDialog';

const ActiveMoneySearcher = (props: any) => {
  const {
    dataType,
    setDataType,
    businessIdentifier,
    setBusinessIdentifier,
    setStakeholderIdentifier,
    stakeholderIdentifier,
    factoringRequestId,
    setFactoringRequestId,
    setFolio,
    folio,
    searchData,
    pagination,
  } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleSearch = async () => {
    if (businessIdentifier && !validaRut(businessIdentifier)) {
      setAlertMessage('El rut ingresado no es válido');
      setOpenAlert(true);
      return;
    }
    if (stakeholderIdentifier && !validaRut(stakeholderIdentifier)) {
      setAlertMessage('El rut ingresado no es válido');
      setOpenAlert(true);
      return;
    }

    await searchData({ page: 0, pageSize: pagination.pageSize });
  };

  return (
    <div style={{ paddingLeft: '10px' }}>
      <MenuDialog buttonLabel="Buscar" buttonColor="primary">
        <div style={{ padding: '10px' }}>
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            Parámetros de búsqueda
          </Typography>
          {setDataType && (
            <div style={{ padding: '5px 0px' }}>
              <FormControl fullWidth>
                <InputLabel id="dataType-label">Tipo de cartera</InputLabel>
                <Select
                  labelId="dataType-label"
                  id="dataType"
                  value={dataType}
                  onChange={(e) => setDataType(e.target.value)}
                  label="Tipo de cartera"
                >
                  <MenuItem value="active">Activa</MenuItem>
                  <MenuItem value="completed">Completada</MenuItem>
                  <MenuItem value="all">Todas</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          {setBusinessIdentifier && (
            <div style={{ padding: '5px 0px' }}>
              <TextField
                label="Rut emisor"
                value={businessIdentifier}
                onChange={(e) => setBusinessIdentifier(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </div>
          )}
          {setStakeholderIdentifier && (
            <div style={{ padding: '5px 0px' }}>
              <TextField
                label="Rut contraparte"
                value={stakeholderIdentifier}
                onChange={(e) => setStakeholderIdentifier(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </div>
          )}
          {setFactoringRequestId && (
            <div style={{ padding: '5px 0px' }}>
              <TextField
                label="Id de solicitud"
                value={factoringRequestId}
                onChange={(e) => setFactoringRequestId(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </div>
          )}
          {setFolio && (
            <div style={{ padding: '5px 0px' }}>
              <TextField
                label="Folio"
                value={folio}
                onChange={(e) => setFolio(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </div>
          )}
          <div style={{ padding: '10px 0px', textAlign: 'right' }}>
            <CustomButton color="primary" onClick={() => handleSearch()}>
              Buscar
            </CustomButton>
          </div>
          <CustomAlert
            open={openAlert}
            title="Problemas con la búsqueda"
            text={alertMessage}
            onClose={() => setOpenAlert(false)}
            severity="error"
            setOpen={setOpenAlert}
          />
        </div>
      </MenuDialog>
    </div>
  );
};

export default ActiveMoneySearcher;
