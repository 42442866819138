import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { getCurseBookReport } from '../../../../../../lib/api/reports';
import GenericSearcher from '../../../../../../lib/common/GlobalSearcher';

const CurseBook: React.FC = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const setUp = async (queryParams: string) => {
    setLoading(true);
    try {
      const response = await getCurseBookReport(dispatch, queryParams);
      setData(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    setUp('');
  }, []);
  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <div>
      <HeaderWithActions
        title="Acá podrás ver todas las solicitudes de factoring que se han financiado."
        variant="body1"
        buttons={[
          {
            name: 'Filtro',
            action: () => {},
            color: 'primary',
            customButton: (
              <GenericSearcher
                dateKey="operationDate"
                filters={[{ type: 'date', label: 'Fecha operación' }]}
                searchData={setUp}
              />
            ),
          },
        ]}
      />
      <Container style={{ padding: 0, margin: 0 }}>
        <EnhancedTable
          dataHeaders={[
            {
              key: 'operationDate',
              label: 'Fecha operación',
              type: 'date',
            },
            { key: 'operationId', label: 'Id Solicitud', type: 'report' },
            { key: 'businessIdentifier', label: 'RUT cliente' },
            { key: 'businessName', label: 'Nombre cliente' },
            { key: 'amount', label: 'Monto operación', type: 'money' },
            {
              key: 'priceDifference',
              label: 'Diferencia de precio',
              type: 'money',
            },
            { key: 'commissionAmount', label: 'Monto comisión', type: 'money' },
            { key: 'comissionIVA', label: 'IVA comisión', type: 'money' },
            { key: 'expensesCost', label: 'Gastos', type: 'money' },
            { key: 'expensesCostIVA', label: 'IVA gastos', type: 'money' },
            // { key: 'receivableAmount', label: 'Aplica CxC', type: 'money' },
            {
              key: 'documentsAmount',
              label: 'Aplica documentos',
              type: 'money',
            },
            { key: 'amountRemaining', label: 'Monto restante', type: 'money' },
            { key: 'remittedAmount', label: 'Total giro', type: 'money' },
            { key: 'documentType', label: 'Tipo documento' },
            { key: 'advancePercentage', label: 'Porcentaje anticipado' },
            { key: 'interestRate', label: 'Tasa negociada' },
            { key: 'arrearRate', label: 'Tasa mora' },
            { key: 'executive', label: 'Ejecutivo' },
          ]}
          data={data}
          actions={[]}
          showActions
          rowsPerPageDefault={100}
          exportToFile="xlsx"
        />
      </Container>
    </div>
  );
};

export default CurseBook;
