import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';
import { markPaymentAsExecuted } from '../../../../../../../../../../lib/api';
import {
  executePaymentRequestPassivePayouts,
  executePaymentRequestPayouts,
} from '../../../../../../../../../../lib/api/payments';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';
import {
  formatDate,
  validatePermissions,
} from '../../../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../../../services/redux/authSlice';
import NomineeExporter from '../../../../../../../../../../lib/common/NomineeExporter';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'businessOriginName', label: 'Cuenta Origen' },
  { key: 'bankAccountOriginName', label: 'Banco Origen' },
  { key: 'businessDestinationName', label: 'Cuenta Destino' },
  { key: 'bankAccountDestinationName', label: 'Banco Destino' },
  { key: 'executedAt', label: 'Giro', type: 'date' },
  { key: 'amount', label: 'Monto', type: 'money' },
];

const PayOutDetailPayouts = (props: any) => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const { payments, source, sourceId, setSuccess, setFailure, reset } = props;
  console.log(payments);
  const [selectedPayments, setSelectedPayments] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [nomineeExporterOpen, setNomineeExporterOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const newFinancingDate = new Date();
  newFinancingDate.setUTCHours(12);
  const [executedAt, setExecutedAt] = useState(newFinancingDate);
  const dispatch = useDispatch();

  const cantSelectRow = (row: any) => {
    return row.executedAt;
  };

  const canSelectAll = () => {
    return !payments.every((payment: any) => payment.executedAt);
  };

  const executePayments = async () => {
    setLoading(true);
    console.log('source', source);
    try {
      if (source === 'payment-request') {
        await executePaymentRequestPayouts(dispatch, sourceId, {
          payoutsIds: selectedPayments,
          executedAt,
        });
      } else if (source === 'payment-request-passive') {
        await executePaymentRequestPassivePayouts(dispatch, sourceId, {
          payoutsIds: selectedPayments,
          executedAt,
        });
      } else if (source === 'factoring-request') {
        await markPaymentAsExecuted(dispatch, {
          id: sourceId,
          paymentsIds: selectedPayments,
          executedAt,
        });
      }
      setOpen(false);
      await reset();
      setSuccess('Pagos completados correctamente');
    } catch (error) {
      console.log('error', error);
      setFailure('Error al completar los pagos');
    }
    setLoading(false);
  };

  const handleFinancingAtChange = (e: any) => {
    const date = new Date(e.target.value);
    date.setUTCHours(12);
    setExecutedAt(date);
  };

  const buttons: CustomButtonProps[] = [
    {
      action: () => setNomineeExporterOpen(true),
      name: 'Exportar',
      color: 'secondary',
    },
    {
      action: () => setOpen(true),
      name: 'Completar pagos',
      color: 'primary',
      disabled:
        selectedPayments.length === 0 ||
        !validatePermissions(user, 'financing_payout_execute'),
    },
  ];

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá puedes ver los pagos realizados."
        variant="body1"
        buttons={buttons}
      />
      <EnhancedTable
        dataHeaders={headers}
        data={payments}
        selected={selectedPayments}
        setSelected={setSelectedPayments}
        canSelectAll={canSelectAll()}
        cantSelectRow={cantSelectRow}
        finalSumRow
      />
      <NomineeExporter
        open={nomineeExporterOpen}
        setOpen={setNomineeExporterOpen}
        payments={payments}
        id={sourceId}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Completar pagos</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro que deseas completar los pagos seleccionados?
          </Typography>
          <Typography>
            Estos serán completados con la fecha de financiamiento:
          </Typography>
          <div style={{ marginTop: 10, textAlign: 'center' }}>
            <TextField
              value={formatDate(executedAt)}
              onChange={handleFinancingAtChange}
              type="date"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton color="secondary" onClick={() => setOpen(false)}>
            Cancelar
          </CustomButton>
          <CustomButton color="primary" onClick={executePayments}>
            Confirmar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PayOutDetailPayouts;
