import axios from 'axios';
import { logout } from '../services/redux/authSlice';
export const api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`, //'https://api.faaast.cl',
  withCredentials: true,
});

export const requestHandlerPublic = async ({
  method,
  url,
  data,
  headers,
  responseType,
}: RequestHandlerParams) => {
  try {
    const options: any = {
      method,
      url,
      data,
      headers,
    };
    if (responseType) options.responseType = responseType;

    return await api.request(options);
  } catch (error: any) {
    if (error.response && error.response.status === 500) {
      console.log(error);
      throw error;
      // } else if (error.response && error.response.status === 404) {
      //   window.location.href = '/'
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const authenticateUser = async (
  username: string,
  password: string,
  secretId?: string,
) => {
  try {
    const response = await api.post('public/auth/login', {
      email: username,
      password,
      secretId,
    });
    console.log(response);
    return response.data;
  } catch (error: any) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      return false;
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const sendPasswordResetEmail = async (data: any) => {
  const response = await requestHandlerPublic({
    method: 'post',
    url: 'public/auth/sendPasswordResetEmail',
    data,
  });
  if (response) return response.data;
};

export const confirmUserRegistrationCode = async (data: any) => {
  const response = await requestHandlerPublic({
    method: 'post',
    url: 'public/auth/confirmUserRegistration',
    data,
  });
  if (response) return response.data;
};

export const confirmUserPasswordRecovery = async (data: any) => {
  const response = await requestHandlerPublic({
    method: 'post',
    url: 'public/auth/confirmPasswordRecovery',
    data,
  });
  if (response) return response.data;
};

export const updatePasswordRecovery = async (data: any) => {
  const response = await requestHandlerPublic({
    method: 'post',
    url: 'public/auth/confirmPasswordRecovery/updatePassword',
    data,
  });
  if (response) return response.data;
};

export const registerUser = async (data: any) => {
  try {
    const response = await api.post('public/auth/register', data);
    console.log(response);
    return response.data;
  } catch (error: any) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      return false;
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const logoutUser = async (dispatch: any) => {
  try {
    const response = await api.post('internal/auth/logout');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

type RequestHandlerParams = {
  method: string;
  url: string;
  dispatch?: any;
  data?: any;
  headers?: Record<string, string>;
  responseType?: string;
};

export const requestHandler = async ({
  method,
  url,
  dispatch,
  data,
  headers,
  responseType,
}: RequestHandlerParams) => {
  try {
    const options: any = {
      method,
      url,
      data,
      headers,
    };
    if (responseType) options.responseType = responseType;

    const response = await api.request(options);
    if (response.status === 205) {
      return window.location.reload();
    }
    return response;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      // Venció el token
      console.log(error.response);
      console.log('logout');
      if (dispatch) dispatch(logout());
      return false;
    } else if (error.response && error.response.status === 403) {
      // No tiene permisos
      alert('No tienes permisos para realizar esta acción');
      throw error;
    } else if (error.response && error.response.status === 500) {
      // Se cayó la app
      console.log(error);
      throw error;
    } else {
      // Error mío
      console.log(error);
      throw error;
    }
  }
};

// My businesses

export const getActiveBusinessRelationsForUser = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: 'internal/users/businessRelations',
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessRelation = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/businessRelations/${businessId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessInvoicesSummary = async (
  dispatch: any,
  id: string,
  type: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/${type}/summary`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessCessionsSummary = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/cessions/summary`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessF29Summary = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/f29/summary`,
    dispatch,
  });
  if (response) return response.data;
};
export const getBusinessF22Summary = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/f22/summary`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessBankruptcyPosts = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/bankruptcy`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessTgr = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/tgr`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessTgrProceedings = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/tgr/proceedings`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessTgDebt = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/tgr/debts/certificate`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentsAvailablesForFactoring = async (
  dispatch: any,
  id: string,
  subProductTypeId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/documents/availables/subproduct/${subProductTypeId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessCrossInvoicesSummary = async (
  dispatch: any,
  id: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/crossInvoices/summary`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessInvoicesByDateRange = async (
  dispatch: any,
  id: string,
  startDate: string,
  endDate: string,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${id}/invoices/dateRange`,
    dispatch,
    data: { startDate, endDate },
  });
  if (response) return response.data;
};

export const getBusiness = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

// IVA
export const uploadIVA = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/iva/emptyer`,
    data: data.data,
    headers,
    dispatch,
  });
  if (response) return response.data;
};

// Business financial states
export const uploadFinancialStatement = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/financialStatement`,
    data: data.data,
    headers,
    dispatch,
  });
  if (response) return response.data;
};

export const getFinancialStatements = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/financialStatement`,
    dispatch,
  });
  if (response) return response.data;
};

export const analizeFinancialStatement = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/financialStatement/${data.financialStatementId}/analize`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFinancialNodesForAssignation = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/financialStatement/${data.financialStatementId}/analize`,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteUploadedBalance = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/business/${businessId}/financialStatement/${data.financialStatementId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getUploadedSignedUrl = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/financialStatement/${data.financialStatementId}/download`,
    dispatch,
  });
  if (response) return response.data;
};

export const assignFinancialStatement = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/financialStatement/${data.financialStatementId}/assign`,
    data: data.assignations,
    dispatch,
  });
  if (response) return response.data;
};

export const getFinancialStatementAssignationsSummary = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/financialStatement/summary${data.emptyer ? `?emptyer=true` : ''}`,
    dispatch,
  });
  if (response) return response.data;
};

// Company financialTrees
export const getFinancialTrees = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/financialTrees`,
    dispatch,
  });
  if (response) return response.data;
};

// Last invoices

export const getInvoicesWithStatus = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/invoices`,
    dispatch,
  });
  if (response) return response.data;
};

// Enrolation flow
export const createBusiness = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const startEnrolation = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/enrollment/start`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const validateCredentials = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/enrollment/validateCredentials`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

// Admin Routes
export const getCompanyUsers = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/admin/users`,
    dispatch,
  });
  if (response) return response.data;
};

export const getUsersRelations = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/admin/users/${data.id}/businessRelations`,
    dispatch,
  });
  if (response) return response.data;
};

export const createUsersRelations = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/admin/users/${data.id}/businessRelations`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const deleteUsersRelations = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/admin/users/${data.id}/businessRelations`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const createUser = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/admin/users`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const resendUserEmail = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/admin/users/${data.userId}/reSendEmail`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const updateUser = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/admin/users/${data.id}`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const deleteUser = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/admin/users/${data.id}`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

// User Profile
export const getUserEncryptedId = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/${data.id}/generateEcryptedId`,
    dispatch,
  });
  if (response) return response.data;
};

export const getUserProfile = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/${data.id}/profile`,
    dispatch,
  });
  if (response) return response.data;
};

export const getUser = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

// Public enrolation Routes

export const getUserByEncryptedId = async (data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `public/users/encrypted/${data.id}`,
  });
  if (response) return response.data;
};

export const createBusinessPublic = async (data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `public/business?id=${data.id}`,
    data,
  });
  if (response) return response.data;
};

export const startEnrolationPublic = async (data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `public/enrollment/start?id=${data.id}`,
    data,
  });
  if (response) return response.data;
};

export const validateCredentialsPublic = async (data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `public/enrollment/validateCredentials?id=${data.id}`,
    data,
  });
  if (response) return response.data;
};

// Financial Statemetn habdling

export const addFinancialStatementNode = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/financialStatements/nodes`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const editFinancialStatementNode = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/financialStatements/nodes`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

// Permissionsz

export const getAllRoles = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/admin/roles`,
    dispatch,
  });
  if (response) return response.data;
};

export const assignRoleToUser = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/admin/users/${data.id}/assginRole`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

// Factoring

export const addMessageToRequest = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/chat/requestType/${data.requestType}/requestId/${data.requestId}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getMessagesFromRequest = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/chat/requestType/${data.requestType}/requestId/${data.requestId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAllFactorings = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/factoring/companies`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAllSiiViews = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/invoices/getSiiViews`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFactoringRequestsReceived = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/factoring/reciever/requests`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFactoringRequestsReceivedFiltered = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/factoring/reciever/requests/business/${businessId}`,
    dispatch,
  });
  if (response) return response.data;
};
export const getFactoringRequestsSent = async (
  dispatch: any,
  companyRecieverId?: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/factoring/sender/request?companyRecieverId=${companyRecieverId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAllFactoringQuotesSentFiltered = async (
  dispatch: any,
  companyId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/factoring/sender/quotes?companyId=${companyId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFactoringRequestSentWithQuotesSummary = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/factoring/sender/request/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getSenderFactoringRequest = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/factoring/sender/request/${data.requestId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getSenderFactoringQuote = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/factoring/sender/request/${data.requestId}/quotes/${data.quoteId}`,
    dispatch,
  });
  if (response) return response.data;
};
export const getRecieverFactoringRequest = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/factoring/reciever/requests/${data.requestId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFactoringQuoteEvaluations = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/factoring/reciever/quotes/${data.quoteId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getRecieverFactoringQuote = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/factoring/reciever/quotes/${data.quoteId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateFactoringRequest = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/factoring/request`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getTemporaryBusinessRelations = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/temporaryBusinessRelations`,
    dispatch,
  });
  if (response) return response.data;
};

export const getPendingFactoringRequest = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/factoring/pendingRequests`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFactoringRequestExtraData = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/factoring/request/${data.id}/extraData`,
    dispatch,
    responseType: 'blob',
  });
  return response;
};

export const getTemporaryBusinessViewsForExecutive = async (
  dispatch: any,
  id: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/reciever/temporaryViews/business/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getTemporaryBusinessForExecutives = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/reciever/temporaryViews`,
    dispatch,
  });
  if (response) return response.data;
};

export const gettUserExecutives = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/executives`,
    dispatch,
  });
  if (response) return response.data;
};

export const getTemporaryConnectionsUserProviderBusiness = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/provider/temporaryViews/business/${businessId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const createTemporaryView = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/users/provider/temporaryViews/business/${data.businessId}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteTemporaryView = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/users/provider/temporaryViews/business/${businessId}/user/${data.recipientId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const editTemporaryView = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/users/provider/temporaryViews/business/${data.businessId}/user/${data.recipientId}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

// Files

export const getFilesFromFactoringRequest = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/factoring/quote/${data.id}/files`,
    dispatch,
  });
  if (response) return response.data;
};

export const downloadFileFromFactoringRequests = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/factoring/quote/${data.id}/files/download?fileName=${data.fileName}`,
    dispatch,
  });
  if (response) return response.data;
};

export const uploadFilesToQuote = async (
  dispatch: any,
  data: any,
  id: number,
) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const response = await requestHandler({
    method: 'patch',
    url: `internal/factoring/quote/${id}/files`,
    data,
    headers,
    dispatch,
  });
  if (response) return response.data;
};

export const isAFactoringCompany = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `public/auth/company/${id}/isFactoring`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAssistantResponseADC = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/users/assistant/adc/getResponse`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const reportFinancialStamentError = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/events/fs-error`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getFinancialStatementsStats = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/financialStatements/getFinancialStatementsStats`,
    dispatch,
  });
  if (response) return response.data;
};

export const getPendingFinancialStatementsForAssignation = async (
  dispatch: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/financialStatements/getPendingFinancialStatementsForAssignation`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFinancialStatementsForAssignated = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/financialStatements/getFinancialStatementsForAssignated`,
    dispatch,
  });
  if (response) return response.data;
};

export const getScoringSheets = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/scoring/availableSheets`,
    dispatch,
  });
  if (response) return response.data;
};

export const getScoringMatrix = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/scoring/ratingMatrix`,
    dispatch,
  });
  if (response) return response.data;
};

export const createSheetAssignation = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/scoring/assignations`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const editSheetAssignation = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/scoring/assignations`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getScoringAssginations = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/assignations`,
    dispatch,
  });
  if (response) return response.data;
};

export const getScoringAssgination = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/scoring/assignations/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};
export const selectAssignationSheet = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/scoring/assignations/select`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getConsultorResponse = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/consultor/orchestrator`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getNodesForDecisionAvailables = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/decisionNode/availableNodes?revisionObjective=${data.revisionObjective}`,
    dispatch,
  });
  if (response) return response.data;
};

export const saveDecisionTree = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/decisionEngine/save`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getAllDecisionEngines = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/decisionEngine`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAllFinalNodes = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/decisionNode/finalNodes`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDecisionEngineAssignations = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/decisionEngine/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateDecisionEngine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'put',
    url: `internal/decisionEngine/${data.id}/update`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getSelectedDecisionEngine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/decisionEngine/selected?revisionObjective=${data.revisionObjective}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCompanySelectedDecisionEngine = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/${data.id}/decisionEngine/selected`,
    dispatch,
  });
  if (response) return response.data;
};

export const executeDecisionEngine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/decisionEngine/execute`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const saveDecisionEngineAssignation = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/decisionEngine/execute/saveResults`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getWhitelists = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/whitelist`,
    dispatch,
  });
  if (response) return response.data;
};

export const createWhitelist = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/whitelist`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteWhitelist = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/whitelist/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBlacklists = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/blacklist`,
    dispatch,
  });
  if (response) return response.data;
};

export const createBlacklists = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/blacklist`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteBlacklists = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/blacklist/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getNotifications = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/notifications`,
    dispatch,
  });
  if (response) return response.data;
};

export const markNotificationsAsRead = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/users/notifications/read`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getUnReadNotifications = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/notifications/unRead`,
    dispatch,
  });
  if (response) return response.data;
};

export const getOwnCompanyProducts = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/product`,
    dispatch,
  });
  if (response) return response.data;
};

export const createProduct = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/product`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getProduct = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/product/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAvailableProducts = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/product/available`,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteProduct = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/product/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateProduct = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/product/${data.id}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

// CreditLine

export const getCreditLinesRequested = async (
  dispatch: any,
  clientId?: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/receiver?clientId=${clientId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateFactoringFileName = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/factoring/quote/${data.id}/files`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getRequirementsForProduct = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/${data.creditLineId}/requirements`,
    dispatch,
  });
  if (response) return response.data;
};

export const createCreditLine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditLine`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const requestCreditLine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditLine/${data.id}/request`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const requestMoreInformationCreditLine = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditLine/${data.id}/requestMoreInformation`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const requestCreditLineValidated = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/creditLine/${data.id}/validate`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getConcentrations = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/${data.id}/concentrations`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCreditLineMissingApprovals = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/${data.id}/missingApprovals`,
    dispatch,
  });
  if (response) return response.data;
};

export const makeApprovalCreditLine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditLine/${data.id}/approval`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const makeRejectCreditLine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditLine/${data.id}/reject`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getCreditLineForReceiver = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/receiver/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCreditLineForGiver = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/giver/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCreditLinesForGiver = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/giver`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessPayers = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/payers`,
    dispatch,
  });
  if (response) return response.data;
};

export const getProductSubProducts = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/${id}/subProducts`,
    dispatch,
  });
  if (response) return response.data;
};

export const getRequestEvaluations = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/decisionEngine/requestType/${data.requestType}/requestId/${data.requestId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const reviewCreditLine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditLine/${data.id}/review`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const rejectCreditLine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditLine/${data.id}/reject`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const saveConcentrations = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/creditLine/${data.id}/concentrations`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const assginCreditLine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/creditLine/${data.id}/assign`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getCreditLineAssignations = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/${data.id}/assignations`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCreditLineDetails = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/giver/${data.creditLineId}/details`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAvailableClientsProductsForCreditLine = async (
  dispatch: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/availableClientsProducts`,
    dispatch,
  });
  if (response) return response.data;
};

export const freezeCreditLine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/creditLine/${data.id}/freeze`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const unFreezeCreditLine = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/creditLine/${data.id}/unfreeze`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const postCollectionManagement = async (
  dispatch: any,
  data: {
    description: string;
    optionId: number;
    type: string;
    documentId: string;
    state: string;
    dueDate: string;
    completedDate: string | null;
  },
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/collection/`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getCollectionManagementsByCompanyId = async (
  dispatch: any,
  query: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection?${query}`,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteCollectionManagementById = async (
  dispatch: any,
  id: number,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/collection/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const createDocumentsFromCompany = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/company/createDocuments`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getComitees = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/comitee`,
    dispatch,
  });
  if (response) return response.data;
};

export const createComitee = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/comitee`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getComitee = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/comitee/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteComitee = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/comitee/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateComitee = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/comitee/${data.id}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const createDecisionNode = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/decisionNode`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getDecisionNode = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/decisionNode/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteDecisionNode = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/decisionNode/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateDecisionNode = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/decisionNode/${data.id}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getMissingFactoringApprovals = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/factoring/quote/${data.id}/missingApprovals`,
    dispatch,
  });
  if (response) return response.data;
};

export const syncInvoicesCessionWithSII = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/factoring/quote/${data.quoteId}/checkCessionAvailable`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessDetails = async (dispatch: any, businessId: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/open/details`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateBusinessDetails = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/business/${businessId}/open/details`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessFactoringOperativeSummary = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/operative/factoring/summary`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessContacts = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/open/contacts`,
    dispatch,
    responseType: 'json',
  });
  if (response) return response.data;
};

export const getBusinessContactsByIdentifier = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/contact/business/${data.identifier}`,
    dispatch,
  });
  if (response) return response.data;
};

export const createContact = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/contact`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const editContact = async (dispatch: any, id: number, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/contact/${id}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const createBusinessContact = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/open/contacts`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteBusinessContact = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/business/${businessId}/open/contacts/${data.contactId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateBusinessContact = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/business/${businessId}/open/contacts/${data.contactId}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessBankAccounts = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/open/bankAccounts`,
    dispatch,
  });
  if (response) return response.data;
};

export const createBusinessBankAccount = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/open/bankAccounts`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteBusinessBankAccount = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/business/${businessId}/open/bankAccounts/${data.bankAccountId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateBusinessBankAccount = async (
  dispatch: any,
  businessId: string,
  bankAccountId: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/business/${businessId}/open/bankAccounts/${bankAccountId}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const selectContact = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/open/contacts/${data.contactId}/select`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const selectBankAccount = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/open/bankAccounts/${data.bankAccountId}/select`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getAllCompanyContacts = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/contact/`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessByIdentifier = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/identifier/${data.identifier}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFactoringRequestBankAccounts = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/factoring/request/${data.id}/bankAccounts`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFactoringPayments = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/factoring/request/${data.id}/payments`,
    dispatch,
  });
  if (response) return response.data;
};

export const savePayments = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/factoring/request/${data.id}/payments`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const markPaymentAsExecuted = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/factoring/request/${data.id}/payments/execute`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getAllPayouts = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/payouts`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCompanyNetwork = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/network`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFactoringBalanceByPayers = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/balance/factoring/${data.identifier}/payers?productTypeId=factoring&quoteId=${data.quoteId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getQuoteAppliedDocuments = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/conciliation/quote/${data.quoteId}/documentsApplied`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAvailableSubProductsForFactoring = async (
  dispatch: any,
  businessId: number,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/factoring/availableSubProducts`,
    dispatch,
  });
  if (response) return response.data;
};

export const getSubProductConditions = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${data.businessId}/factoring/subProduct/${data.subProductId}/conditions`,
    dispatch,
  });
  if (response) return response.data;
};

// Funds

export const getCompanyFunds = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/fund`,
    dispatch,
  });
  if (response) return response.data;
};

export const createFund = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/fund`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getFund = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/fund/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteFund = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/fund/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateFund = async (dispatch: any, id: string, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/fund/${id}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const selectFundAsDefault = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/fund/${id}/selectAsDefault`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentsAssignedToFund = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/fund/${data.id}/documents`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentForAssignation = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/fund/${data.id}/documents/available`,
    dispatch,
  });
  if (response) return response.data;
};

export const assignDocumentToFund = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/fund/${data.id}/documents/assign`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getFundPayments = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/fund/${data.id}/payments`,
    dispatch,
  });
  if (response) return response.data;
};

export const createFundPayments = async (dispatch: any, id: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/fund/${id}/payments`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const updateFundPayment = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/fund/${data.id}/payments/${data.paymentId}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getPaymentSuggestions = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/fund/${data.id}/payments/suggestions`,
    dispatch,
  });
  if (response) return response.data;
};

export const getInvoicesByEncriptionBusinessIdentifier = async (
  encryptedCompanyIdentifier: string,
  encryptedBusinessIdentifier: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `public/paymentLink/${encryptedCompanyIdentifier}/${encryptedBusinessIdentifier}`,
  });
  if (response) return response.data;
};

export const getInvoiceEncrypted = async (
  encryptedCompanyIdentifier: string,
  encryptedBusinessIdentifier: string,
  invoiceId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `public/paymentLink/invoice/${encryptedCompanyIdentifier}/${encryptedBusinessIdentifier}/${invoiceId}`,
  });
  if (response) return response.data;
};

export const createPaymentNotification = async (data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `public/paymentNotification`,
    data,
  });
  if (response) return response.data;
};

export const getBankAccount = async (
  encryptedCompanyId: string,
  encryptedBusinessIdentifier: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `public/paymentLink/bankAccount/${encryptedCompanyId}/${encryptedBusinessIdentifier}`,
  });
  if (response) return response.data;
};

export const getPaymentNotifications = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/paymentNotifications`,
    dispatch,
  });
  if (response) return response.data;
};

export const reviewPaymentNotification = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/paymentNotifications/${id}/review`,
    dispatch,
  });
  if (response) return response.data;
};

export const unReviewPaymentNotification = async (
  dispatch: any,
  id: number,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/paymentNotifications/${id}/unreview`,
    dispatch,
  });
  if (response) return response.data;
};

export const automaticCollectionEnrollment = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/collection/automaticCollection`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const checkDocumentsPreselection = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/factoring/preSelectionDocuments`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const automaticCollectionUnenrollment = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/collection/automaticCollection/unenroll`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getPaymentNotificationByInvoice = async (
  dispatch: any,
  invoiceId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/paymentNotifications/invoice/${invoiceId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const createPendingBusinessAccount = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/pendingAccount`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const editPendingBusinessAccount = async (
  dispatch: any,
  pendingAccountId: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/pendingAccount/${pendingAccountId}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessPendingBusinessAccounts = async (
  dispatch: any,
  id: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/pendingAccount`,
    dispatch,
  });
  if (response) return response.data;
};

export const getPendingBusinessAccounts = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/pendingAccount`,
    dispatch,
  });
  if (response) return response.data;
};

export const getPendingAccountsCategories = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/pendingAccountCategory`,
    dispatch,
  });
  if (response) return response.data;
};

export const editPendingAccountsCategory = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/pendingAccountCategory/${id}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const createPendingAccountsCategory = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/pendingAccountCategory`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deletePendingAccountsCategory = async (
  dispatch: any,
  id: number,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/pendingAccountCategory/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getPendingAccountsCategory = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/pendingAccountCategory/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updatePendingAccountsCategory = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/pendingAccountCategory/${id}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentAvailablesForPaymentRequest = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/paymentRequest/documents`,
    dispatch,
  });
  if (response) return response.data;
};

export const getPendingAccountsAvailablesForPaymentRequest = async (
  dispatch: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/paymentRequestPassive/pendingAccounts`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBankAccountsForNominee = async (
  dispatch: any,
  businessIdentifier: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/getBankAccountsForNominee/${businessIdentifier}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getLastTGRScrapDates = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/enrollment/getAllLastScrapsByBusinessAndScraperIds?businessId=${businessId}&scraperIds=tgr-collection,tgr-debt,tgr-agreement`,
    dispatch,
  });
  if (response) return response.data;
};

export const createNotRecognizedPayment = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/conciliation/notRecognized`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getAllNotRecognizedPayments = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/notRecognizedPayment`,
    dispatch,
  });
  if (response) return response.data;
};

export const getNotRecognizedPayment = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/notRecognizedPayment/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const createAndAssignPendingAccountsToNotRecognizedPayment = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/notRecognizedPayment/${id}/assignPendingAccounts`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getAutoCollectionConfig = async (
  dispatch: any,
  businessIdentifier: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/options/autoCollection/${businessIdentifier}`,
    dispatch,
  });
  if (response) return response.data;
};
export const getDocumentAlerts = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/documentAlert`,
    dispatch,
  });
  if (response) return response.data;
};

export const postAutoCollectionConfig = async (
  dispatch: any,
  businessIdentifier: string,
  contactIds: number[],
  daysToCollect: number,
  active: boolean,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/collection/options/autoCollection`,
    data: { businessIdentifier, contactIds, daysToCollect, active },
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentAlertsByCompany = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/documentAlert/company`,

    dispatch,
  });
  if (response) return response.data;
};

export const getFutureCollectionManagementsByCompanyId = async (
  dispatch: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/next`,
    dispatch,
  });
  if (response) return response.data;
};

export const selectContactInvoicing = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/open/contacts/${data.contactId}/select/invoicing`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const unSelectContactInvoicing = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/open/contacts/${data.contactId}/unselect/invoicing`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getIntegrationsCatalog = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/integrations/catalog`,
    dispatch,
  });
  if (response) return response.data;
};

export const getIntegrationStatus = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/integrations/${id}/status`,
    dispatch,
  });
  if (response) return response.data;
};

export const getIntegrationStatusByInternalName = async (
  dispatch: any,
  internalName: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/integrations/status/internalName/${internalName}`,
    dispatch,
  });
  if (response) return response.data;
};

export const activateIntegration = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/integrations/${id}/activate`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const getPublicationStatus = async (
  dispatch: any,
  folio: string,
  businessIdentifier: string,
  stakeholderIdentifier: string,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/documentPublication/status`,
    dispatch,
    data: { folio, businessIdentifier, stakeholderIdentifier },
  });
  if (response) return response.data;
};
