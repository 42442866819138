import React from 'react';
import { Dialog, DialogActions } from '@mui/material';
import EnhancedTab from '../../../../../../../../lib/designSystem/TabSimulation';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import PayOutSummary from './components/Summary';
import PayOutDetailPayouts from './components/Payouts';
import PayOutDetailAppliedDocuments from './components/AppliedDocuments';
import PayOutDetaiPendingAccounts from './components/PendingAccounts';
import PayOutDetailDocuments from './components/Documents';

const tabsBySource = (
  request: any,
  setSuccess: any,
  setFailure: any,
  reset: any,
) => {
  const { source } = request;

  const summary = {
    index: 0,
    label: 'Resumen',
    component: <PayOutSummary request={request} />,
  };

  const payments = {
    index: 0,
    label: 'Pagos',
    component: (
      <PayOutDetailPayouts
        payments={request.payments}
        sourceId={request.sourceId}
        source={request.source}
        setSuccess={setSuccess}
        setFailure={setFailure}
        reset={reset}
      />
    ),
  };

  const appliedDocuments = {
    index: 1,
    label: 'Documentos Aplicados',
    component: (
      <PayOutDetailAppliedDocuments
        documentsApplied={request.documentsApplied}
        source={request.source}
        sourceId={request.sourceId}
        setSuccess={setSuccess}
        setFailure={setFailure}
        reset={reset}
      />
    ),
  };

  const documents = {
    index: 2,
    label: 'Documentos',
    component: (
      <PayOutDetailDocuments
        documents={request.documents}
        source={request.source}
        sourceId={request.sourceId}
        setSuccess={setSuccess}
        setFailure={setFailure}
        reset={reset}
      />
    ),
  };

  const pendingAccouns = {
    index: 3,
    label: 'Cuentas pendientes',
    component: (
      <PayOutDetaiPendingAccounts
        pendingAccounts={request.pendingAccounts}
        source={request.source}
        sourceId={request.sourceId}
        setSuccess={setSuccess}
        setFailure={setFailure}
        reset={reset}
      />
    ),
  };

  switch (source) {
    case 'factoring-request':
      return [summary, payments];
    case 'payment-request':
      return [summary, documents, payments];
    case 'payment-request-passive':
      return [summary, pendingAccouns, appliedDocuments, payments];
    default:
      return [];
  }
};

const PayOutDetails = (props: any) => {
  const {
    paymentGroupedDetails,
    open,
    setOpen,
    setSuccess,
    setFailure,
    setUp,
  } = props;
  const startValue = 0;

  const reset = async () => {
    await setUp();
    setOpen(false);
  };

  const tabs = tabsBySource(
    paymentGroupedDetails,
    setSuccess,
    setFailure,
    reset,
  );

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={() => setOpen(false)}>
      <div style={{ padding: 20, minHeight: 200 }}>
        <EnhancedTab
          title={`${paymentGroupedDetails.sourceTranslation} #${paymentGroupedDetails.sourceId} `}
          startValue={startValue}
          tabs={tabs}
        />
      </div>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          {' '}
          Salir{' '}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default PayOutDetails;
